import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/etcd',
        name: 'etcd',
        component: () => import('../views/EtcdView.vue')
    },
    {
        path: '/node-list',
        name: 'node-list',
        component: () => import('../views/NodeList.vue')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('../views/TestPage.vue')
    }
]

// history: createWebHistory(process.env.BASE_URL),

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
