import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import { createPinia } from 'pinia'

loadFonts()

const pinia = createPinia()

createApp(App)
    .use(router)
    .use(store)
    .use(pinia)
    // .use(vuetify)
    .use(ViewUIPlus)
    .mount('#app')
